
import {computed, defineComponent, onMounted, onUnmounted, ref, watch} from "vue";
import {IonCol, IonGrid, IonRow, IonSearchbar} from "@ionic/vue";
import _ from "lodash";
import {sleep} from "@/app/App.vue";

export default defineComponent({
  name: "Autocomplete",
  components: {
    IonSearchbar,
    IonRow,
    IonCol
  },
  props: {
    placeholder: String,
    onSelectElement: Function,
    searchWordRef: String,
    maxResults: {
      type: Number,
      default: 5
    },
    data: Array,
    initialWord: String,
  },
  emits: ['on-focus-out', 'on-open-list', 'on-close-list'],
  setup(props, {emit}) {
    const referenceAutoComplete = ref<any>(null);
    onMounted(() => {
      setTimeout(() => {
        referenceAutoComplete.value?.$el.setFocus();
      }, 100)
    });
    onUnmounted(() => {
      emit('on-close-list');
    })
    const searchWord = ref<any>(props.searchWordRef);
    const selectedElement = ref<any>(props.searchWordRef ? props.searchWordRef : null);
    // const selectedElement = ref<any>(props.searchWordRef);
    const selectElement = (selectedEl: any) => {
      selectedElement.value = selectedEl;
      emit('on-focus-out', selectedElement.value);
    }
    const searchResults = (data: any[] | undefined | null, word: string): any[] => {
      if (!data || !word)
        return [];

      const elementMatch = (el: any, word: string): boolean => {
        const lowerWord = word.toLowerCase();

        if (_.isString(el))
          return el.toLowerCase().startsWith(lowerWord);

        return false;
      };

      const filteredResults = data.filter((dataEl: any): boolean => {
        return elementMatch(dataEl, word)
      });
      const orderedResults = _.sortedUniq(filteredResults.sort());
      return orderedResults.slice(0, props.maxResults);
    };
    const filteredResults = computed(() => searchResults(props.data, searchWord.value));
    const showList = computed(() => searchWord.value !== selectedElement.value && searchWord.value && filteredResults.value.length > 0);

    watch(selectedElement, (selectedEl: any) => {
      if (!selectedEl || !props.onSelectElement)
        return;
      searchWord.value = selectedEl;
      props.onSelectElement(selectedEl);
    });

    watch(props, () => {
      if (props.searchWordRef !== '')
        return;
      selectedElement.value = '';
      searchWord.value = '';
    })

    const onFocusOut = async () => {
      emit('on-focus-out', searchWord.value);
    };

    const selectWord = (word: string) => {
      searchWord.value = word;
      selectedElement.value = word;
    };

    watch(showList, () => {
      if(showList.value)
        emit('on-open-list');
      else
        emit('on-close-list');
    });


    return {
      onFocusOut,
      searchWord,
      filteredResults,
      selectElement,
      showList,
      referenceAutoComplete,
      selectWord
    }
  }
});
