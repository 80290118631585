
import {computed, defineComponent, ref, reactive, watch} from "vue";
import {
  alertController,
  IonButton,
  IonCol,
  IonContent, IonFab, IonFabButton,
  IonGrid, IonHeader, IonIcon, IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonRow, IonTitle, IonToolbar, isPlatform,
  onIonViewWillEnter
} from "@ionic/vue";
import {CreateEntryDelivery} from "@/domain/command/delivery/delivery.command";
import CreateEntryDeliveryItemComponent from "@/app/view/delivery/create/CreateEntryDeliveryItemComponent.vue";
import {useStore} from "vuex";
import router from "@/app/router";
import {useViewStateApi} from "@/domain/service/api/viewState.api";
import _ from "lodash";
import {uuid4} from "@capacitor/core/dist/esm/util";
import {addCircleOutline, addOutline, sendOutline} from "ionicons/icons";
import {useDeliveryApi} from "@/domain/service/api/delivery.api";

export default defineComponent({
  name: "CreateEntryDeliveryPage",
  components: {
    IonFab,
    IonFabButton,
    IonIcon,
    CreateEntryDeliveryItemComponent,
    IonContent,
    IonPage,
    IonButton,
    IonLabel,
    IonItem,
    IonCol,
    IonGrid,
    IonRow,
    IonHeader,
    IonToolbar,
    // IonTitle,
    IonInput,
  },

  setup() {
    const {entryDeliveryBeforeCheck} = useViewStateApi();
    const store = useStore();
    const isTablet = computed<boolean>(() => !isPlatform('desktop'));
    const filterValidItems = (items: Array<any>) => _.reject(items, (item) => item.reference === '');
    const command = ref(store.getters.getCheckCreateEntryDeliveryState);
    const entryDeliveryIndex = computed<string>(() => store.getters.getNextEntryDeliveryIndex);
    const indexInput = ref<string>(store.getters.getNextEntryDeliveryIndex);
    const isAtLeastOneItem = computed<boolean>(() => filterValidItems(command.value.items).length > 0);

    const onIndexIsNotFree = async () => {
      const alert = await alertController
          .create({
            cssClass: 'my-custom-class',
            header: 'Alerta',
            subHeader: 'Número de albarán de entrada inválido',
            message: 'El Número de albarán de entrada que ha introducido ya existe o está vacío, introduzca uno distinto',
            buttons: [
              {
                text: 'Ok',
                role: 'success',
                cssClass: 'danger',
              },
            ],
          });

      await alert.present();

      const {role} = await alert.onDidDismiss();
    }
    const onSendToCheck = async () => {
      const newCommand: CreateEntryDelivery = command.value;
      newCommand.items = filterValidItems(newCommand.items);
      if(!indexInput.value){
        await onIndexIsNotFree();
        return;
      }
      newCommand.indexPosition = indexInput.value;
      await useDeliveryApi().isIndexUsed(_.cloneDeep(indexInput.value));
      if(!store.getters.getIsThisIndexFree){
        await onIndexIsNotFree();
        return;
      }
      entryDeliveryBeforeCheck(newCommand);

      await router.push({name: `checkEntryDelivery`, params: {id: newCommand.id}});
    };


    const addEmptyItem = () => {
      command.value.items.push({
        receivedAmount: 0, expectedAmount: 0, reference: '', id: uuid4(),
      });
    };

    const onNextItem = () => {
      addEmptyItem();
    }

    const onDeleteItem = (index: number) => {
      if (index > -1)
        command.value.items.splice(index, 1);
    }

    onIonViewWillEnter(() => {
      command.value = store.getters.getCheckCreateEntryDeliveryState;
      indexInput.value = store.getters.getNextEntryDeliveryIndex;
    });

    return {
      addCircleOutline,
      sendOutline,
      addOutline,
      isTablet,
      addEmptyItem,
      onSendToCheck,
      command,
      onNextItem,
      onDeleteItem,
      isAtLeastOneItem,
      entryDeliveryIndex,
      indexInput,
    };
  }
})
