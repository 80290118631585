
import {closeCircleOutline} from "ionicons/icons";
import {computed, defineComponent, onBeforeUpdate, onMounted, onUpdated, ref, watch} from "vue";
import {IonCol, IonGrid, IonIcon, IonInput, IonItem, IonLabel, IonRow} from "@ionic/vue";
import {CreateEntryDeliveryItem} from "@/domain/command/delivery/delivery.command";
import {useStore} from "vuex";
import Autocomplete from "@/app/component/autocomplete/Autocomplete.vue";
import {Reference} from "@/domain/model/reference/ref.model";
import _ from "lodash";
import {Material} from "@/domain/model/material/material.model";

export default defineComponent({
  name: "CreateEntryDeliveryItemComponent",
  components: {
    Autocomplete,
    IonInput,
    IonItem,
    IonRow,
    IonCol,
    IonGrid,
    IonIcon,


  },
  props: {
    command: Object,
    index: Number,
  },
  setup: function (props: any, {emit}) {
    const store = useStore();
    const commandItem = ref<CreateEntryDeliveryItem>(props.command);
    const indexItem = ref(props.index);


    const allMaterialsRefs = computed<string[]>(() => store.getters.allMaterial.map((materialNode: Material) => materialNode.ref));
    const isRefIncluded = ref<boolean>(true);


    const materialEntity = computed(() => store.getters.materialByRef(commandItem.value.reference));
    const allMaterial = computed(() => store.getters.allMaterial.map((material: Reference) => material.ref));
    const placeholder = ref('Buscar materia prima...');
    const expectedAmount = ref<any>(0)
    const receivedAmount = ref<any>(0)
    const reference = ref<any>(commandItem.value.reference);


    const onFocusOut = (ref: string) => {
      isRefIncluded.value = _.includes(allMaterialsRefs.value, ref);
    };

    const changeFocus = async () => {
      (await expectedAmount.value?.$el.getInputElement()).focus();
      const element = await expectedAmount.value?.$el.getInputElement();
      setTimeout(() => {
        element.select();
      })
    };
    const onSelectMaterial = (materialRef: string) => {
      commandItem.value.reference = materialRef;
      reference.value = materialRef;

      commandItem.value.expectedAmount = store.getters.lastReferenceAmountReceived(materialRef);
      commandItem.value.receivedAmount = store.getters.lastReferenceAmountReceived(materialRef);

      changeFocus();
    };

    const onNextFocus = async () => {
      receivedAmount.value?.$el.setFocus();
      const element = await receivedAmount.value?.$el.getInputElement();
      setTimeout(() => {
        element.select();
      })
    }

    const copyValue = async () => {
      _.defer(() => commandItem.value.receivedAmount = commandItem.value.expectedAmount);
    };

    const onNextItem = () => {
      emit('on-next-item', 'test');
    };

    const deleteItem = () => {
      emit('on-delete-item', indexItem)
    };

    return {
      isRefIncluded,
      commandItem,
      materialEntity: materialEntity,
      allMaterial,
      placeholder,
      onSelectMaterial,
      indexItem,
      expectedAmount,
      receivedAmount,
      onNextFocus,
      copyValue,
      onNextItem,
      closeCircleOutline,
      deleteItem,
      reference,
      onFocusOut,
    };
  }
})
